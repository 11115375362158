angular.module('rolesConfig',[])
    .constant('rolesConstant', rolesConstant());

    function rolesConstant() {

        var roles = {};

        roles.CUSTOMER = "customer";
        roles.ADMIN = "admin";
        roles.MFG = "mfg";
        roles.LINK = "link";
        return roles;
    }


	var loadPermissions = function (response) {
		var types = {
				"permissions" : {},
				"accountPermissions" : {},
				"adminPermissions" : {}
		};

		_.each(response.data.permissions, function (permission) {
			if(types[permission.type] === undefined) {
				types[permission.type] = {};
			}
			types[permission.type][permission.name] = permission.values;
		});

		return types;
    };

    var loadRoles = function (response) {
				    	var types = {
								"adminRoles": [],
								"accountRoles": [],
								"orgRoles": [],
								"projectRoles": [],
                                "aeRosLinkRoles": []
						};

						_.each(response.data.roles, function (role) {
							if(types[role.type] === undefined) {
								types[role.type] = [];
							}
							types[role.type].push(role);
						});

						angular.module('rolesConfig')
						.constant("adminRoles", types["adminRoles"])
						.constant("accountRoles", types["accountRoles"])
						.constant("orgRoles", types["orgRoles"])
						.constant("projectRoles", types["projectRoles"])
                        .constant("aeRosLinkRoles", types["aeRosLinkRoles"]);
						return types;
				    };
